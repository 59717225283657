import $ from 'jquery';

$(document).on('turbolinks:load', () => {

    (function() {
        "use strict";

        var scdir, hold = false;
        var phone_batch = { current: 0 }, tablet_batch = { current: 0 }, desktop_batch = { current: 0 }; 

        function _scrollY(obj) {

            if (hold === false) {
                hold = true;

                if ($(window).width() < 768) {
                    paginator($('#mobile-projects'), phone_batch);
                } else if (($(window).width() >= 768) && ($(window).width() < 992)){
                    paginator($('#tablet-projects'), tablet_batch);
                } else {
                    paginator($('#desktop-projects'), desktop_batch);
                }

                setTimeout(function() {
                    hold = false;
                }, 2000);
            }

            function paginator(div, batch_ref) {
                let batch = batch_ref.current
                let pages = div.find('.horizontal-sharp-appearing');
                let pages_count = pages.length;
                if (scdir == "down" && batch > 0) {
                    $(pages[batch]).addClass('hidden-batch');
                    batch_ref.current = batch - 1;
                    $(pages[batch_ref.current]).removeClass('hidden-batch');
                } else if (scdir == "up" && batch < (pages_count - 1)) {
                    $(pages[batch]).addClass('hidden-batch');
                    batch_ref.current = batch + 1;
                    if($(pages[batch_ref.current]).hasClass('initially-hidden-batch')) {
                        $(pages[batch_ref.current]).removeClass('initially-hidden-batch');
                        let element = pages[batch_ref.current];
                        // animation restart -,-
                        element.classList.remove('horizontal-sharp-appearing');
                        void element.offsetWidth;
                        element.classList.add('horizontal-sharp-appearing');
                    } else {
                        $(pages[batch_ref.current]).removeClass('hidden-batch');
                    }
                }
            }
        }
        function _swipe(obj) {
            var swdir,
                sX,
                sY,
                dX,
                dY,
                threshold = 100,
                /*[min distance traveled to be considered swipe]*/
                slack = 50,
                /*[max distance allowed at the same time in perpendicular direction]*/
                alT = 500,
                /*[max time allowed to travel that distance]*/
                elT, /*[elapsed time]*/
                stT; /*[start time]*/
            obj.addEventListener('touchstart', function(e) {
                var tchs = e.changedTouches[0];
                swdir = 'none';
                sX = tchs.pageX;
                sY = tchs.pageY;
                stT = new Date().getTime();
                //e.preventDefault();
            }, false);

            obj.addEventListener('touchmove', function(e) {
                //e.preventDefault(); /*[prevent scrolling when inside DIV]*/
            }, false);

            obj.addEventListener('touchend', function(e) {
                var tchs = e.changedTouches[0];
                dX = tchs.pageX - sX;
                dY = tchs.pageY - sY;
                elT = new Date().getTime() - stT;
                if (elT <= alT) {
                    if (Math.abs(dX) >= threshold && Math.abs(dY) <= slack) {
                        swdir = (dX < 0) ? 'left' : 'right';
                    } else if (Math.abs(dY) >= threshold && Math.abs(dX) <= slack) {
                        swdir = (dY < 0) ? 'up' : 'down';
                    }
                    if ($(obj).hasClass('project-page')) {
                        if (swdir === 'up') {
                            scdir = swdir;
                            _scrollY(obj);
                        } else if (swdir === 'down' && obj.style.transform !== 'translateY(0)') {
                            scdir = swdir;
                            _scrollY(obj);

                        }
                        e.stopPropagation();
                    }
                }
            }, false);
        }
        /*[assignments]*/
        var page = $('.project-page');
        if (page.length !== 0) {
          let well = page[0];
            well.addEventListener('wheel', function(e) {
                if (e.deltaY < 0) {
                    scdir = 'down';
                }
                if (e.deltaY > 0) {
                    scdir = 'up';
                }
    
                e.stopPropagation();
            });
            well.addEventListener('wheel', _scrollY);
            _swipe(well);
        }
    })();
    $(".full-page-carousel").hover(()=>{
        $("body").css("overflow-y", "hidden");
    }, ()=>{
        $("body").css("overflow-y", "auto");
    });
});
