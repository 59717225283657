import Player from '@vimeo/player';
import $ from 'jquery';

$(document).on('turbolinks:load', () => {
    scrollingHomepage();
});

function restartAnimationsByClassName(className) {
    $("." + className).each(function (index, element) {
      element.classList.remove(className);
      void element.offsetWidth;
      element.classList.add(className);
    });
  }

function play(player){
  player.getPaused().then(function(paused) {
    if (paused) {
      player.play().then(function() {
      }).catch(function(error) {
        verbose_errors(error)
      });
    }
  });
}

function pause(player){
  player.getPaused().then(function(paused) {
    if (!paused){
      player.pause().then(function() {
      }).catch(function(error) {
        verbose_errors(error)
      });
    }
  });
}

function triggerAnimation(jSlide) {
    let vd_count = $("#hp-info").data("videos");
    let ct_count = $("#hp-info").data("categories");
    for (let index = (vd_count+1); index < (vd_count+ct_count+1); index++) {
        if (jSlide.hasClass('level'+index)) {
            jSlide.find('.outter-section-wrapper').css('opacity', 0);
            setTimeout(function() {
                jSlide.find('.outter-section-wrapper').css('opacity', 1);
                restartAnimationsByClassName('homepage-section-title-inner');
                restartAnimationsByClassName('homepage-section-footer-inner');
                restartAnimationsByClassName('circle');
            }, 500);
            setTimeout(function() {
                restartAnimationsByClassName('large-bar-extreme');
                restartAnimationsByClassName('post-appear');
            }, 1000);
        }
    }
    if (jSlide.hasClass(`level${vd_count+ct_count+1}`)) {
        jSlide.find('.container-sm').css('opacity', 0);
        setTimeout(function() {
            jSlide.find('.container-sm').css('opacity', 1);
            restartAnimationsByClassName('animated-properties');
            restartAnimationsByClassName('large-bar');
            restartAnimationsByClassName('post-appear');
            restartAnimationsByClassName('small-bar');
        }, 500);
    }
    if (jSlide.hasClass(`level${vd_count+ct_count+2}`)) {
        restartAnimationsByClassName('location-dot-aura');
    }
}

function changeNavbarTheme(panel) {
	setTimeout(function() {
		let _body = $('body');
		if ($(panel).hasClass('navbar-black')) {
			if (_body.hasClass('light-theme')){
				_body.removeClass('light-theme');
				_body.addClass('dark-theme');
				_body.find('.logo-white').addClass('d-none');
				_body.find('.logo-black').removeClass('d-none');
			}
		} else if ($(panel).hasClass('navbar-white')) {
			if (_body.hasClass('dark-theme')){
				_body.removeClass('dark-theme');
				_body.addClass('light-theme');
				_body.find('.logo-black').addClass('d-none');
				_body.find('.logo-white').removeClass('d-none');
			}
		}
	}, 500);
}

function scrollingHomepage() {
    "use strict";

    var scdir, hold = true;
    var slide = 1;
    var slides = $('.home-panel');
    setTimeout(function() {
      hold = false
    }, 1000);
    setTimeout(function() {
        $(".home-panel").removeClass("home-not-video");
    }, 2500);
    function _scrollY(obj) {
        if (hold === false) {
            hold = true;
            changeSlide();
            
            setTimeout(function() {
                hold = false
            }, 1000);
        }

        function changeSlide(){
            if (scdir == "down" && slide > 1) {
                $(slides[slide-2]).css('visibility', 'visible');
                $(slides[slide-2]).removeClass('fade-out-klass').removeClass('fade-in-klass');
                $(slides[slide-2]).addClass('fade-in-klass');
                changeNavbarTheme(slides[slide-2]);
                triggerAnimation($(slides[slide-2]));
                slide = slide - 1;
            } else if (scdir == "up" && slide < (slides.length)) {
                $(slides[slide-1]).removeClass('fade-out-klass').removeClass('fade-in-klass');
                $(slides[slide-1]).addClass('fade-out-klass');
                changeNavbarTheme(slides[slide]);
                let prev_slide = $(slides[slide-1])
                setTimeout(function() {
                    prev_slide.css('visibility', 'hidden');
                }, 1000);
                triggerAnimation($(slides[slide]));
                slide = slide + 1;
            }
            let iframe = $('.vimeo-init');
            if (iframe.length !== 0) {
              iframe.each(function(index) {
                let player = new Player(this);
                pause(player);
              });
            }
            let responsive_index = 0;
            if ($(window).width() >= 768) {
              responsive_index = 1;
            }
            let current_panel = $('.panel')[slide-1];
            let iframes = $(current_panel).find('.vimeo-init');

            if (iframes.length !== 0) {
            let iframe = iframes[responsive_index];
            let player = new Player(iframe);
              play(player);
            }
        }
    }
    function _swipe(obj) {
        var swdir,
            sX,
            sY,
            dX,
            dY,
            threshold = 100,
            /*[min distance traveled to be considered swipe]*/
            slack = 50,
            /*[max distance allowed at the same time in perpendicular direction]*/
            alT = 500,
            /*[max time allowed to travel that distance]*/
            elT, /*[elapsed time]*/
            stT; /*[start time]*/
        obj.addEventListener('touchstart', function(e) {
            var tchs = e.changedTouches[0];
            swdir = 'none';
            sX = tchs.pageX;
            sY = tchs.pageY;
            stT = new Date().getTime();
            //e.preventDefault();
        }, false);

        obj.addEventListener('touchmove', function(e) {
            //e.preventDefault(); /*[prevent scrolling when inside DIV]*/
        }, false);

        obj.addEventListener('touchend', function(e) {
            var tchs = e.changedTouches[0];
            dX = tchs.pageX - sX;
            dY = tchs.pageY - sY;
            elT = new Date().getTime() - stT;
            if (elT <= alT) {
                if (Math.abs(dX) >= threshold && Math.abs(dY) <= slack) {
                    swdir = (dX < 0) ? 'left' : 'right';
                } else if (Math.abs(dY) >= threshold && Math.abs(dX) <= slack) {
                    swdir = (dY < 0) ? 'up' : 'down';
                }
                console.log(obj);
                if ($(obj).hasClass('home-panels-wrapper')) {
                    if (swdir === 'up') {
                        scdir = swdir;
                        _scrollY(obj);
                    } else if (swdir === 'down') { // && obj.style.transform !== 'translateY(0)'
                        scdir = swdir;
                        _scrollY(obj);

                    }
                    e.stopPropagation();
                }
            }
        }, false);
    }
    /*[assignments]*/
    var page = $('.home-panels-wrapper');
    if (page.length !== 0) {
      let well = page[0];
        well.addEventListener('wheel', function(e) {
            if (e.deltaY < 0) {
                scdir = 'down';
            }
            if (e.deltaY > 0) {
                scdir = 'up';
            }

            e.stopPropagation();
        });
        well.addEventListener('wheel', _scrollY);
        _swipe(well);

        $('.ball-hover-placeholder').on('click', function() {
            hold = false;
            scdir = 'up';
            _scrollY(well);
        });
    }
}