import $ from 'jquery';
import Player from '@vimeo/player';
import { Carousel } from 'bootstrap';

$(document).on('turbolinks:load', () => {

  let iframe = $('.vimeo-init');
  const players = [];
  if (iframe.length !== 0) {
    iframe.each(function(index) {
		let player = new Player(this);
		players.push(player);

		player.ready().then(function () {
			players.forEach((p, index) => {
				const playerElement = p.element;
				playerElement.classList.add('vimeo-iframe');
			})
		});
    });
  }

  function restartAnimationsByClassName(className) {
    $("." + className).each(function (index, element) {
      element.classList.remove(className);
      void element.offsetWidth;
      element.classList.add(className);
    });
  }

  function restartAnimations() {
	restartAnimationsByClassName('animated-properties');
	restartAnimationsByClassName('large-bar');
	restartAnimationsByClassName('post-appear');
	restartAnimationsByClassName('small-bar');
  }

  carouselScrolling();

  (function() {
	players.forEach(function(player, index) {
		if (($(window).width() >= 768 && index == 1) || ($(window).width() < 768 && index == 0)) {
			player.play();
		} else {
			player.pause();
		}
	});
  })();

  (function() {
    // Script source: https://codepen.io/nearee/pen/zYYENMa
  	"use strict";
  	/*[pan and well CSS scrolls]*/
  	var pnls = document.querySelectorAll('.panel').length;
	let el1 = $('.double-responsive-panels');
	if (el1.length !== 0) {
		if ($(window).width() >= 768) { // readjusting pannels for blog page
			pnls = parseInt(pnls/2) + pnls%2;
		}
	}
  	var scdir, hold = false, animation_hold = false;

  	function _scrollY(obj) {
  		var bslength, slength, plength, pan, step = 100,
  			vh = window.innerHeight / 100,
  			vmin = Math.min(window.innerHeight, window.innerWidth) / 100;
  		if ((this !== undefined && this.id === 'well') || (obj !== undefined && obj.id === 'well')) {
  			pan = this || obj;
  			plength = parseInt(pan.offsetHeight / vh);
  		}
  		if (pan === undefined) {
  			return;
  		}
  		plength = plength || parseInt(pan.offsetHeight / vmin);
  		slength = parseInt(pan.style.transform.replace('translateY(', ''));
  		bslength = parseInt(pan.style.transform.replace('translateY(', ''));
  		if (scdir === 'up' && Math.abs(slength) < (plength - plength / pnls)) {
  			slength = slength - step;
  		} else if (scdir === 'down' && slength < 0) {
  			slength = slength + step;
  		} else if (scdir === 'top') {
  			slength = 0;
  		}
  		if (hold === false) {
  			hold = true;
      		pause_all_players();
       		play_current_panel_vimeo(slength);
            play_animation(bslength, slength);
            setTimeout(function() {
                pan.style.transform = 'translateY(' + slength + 'vh)';
            }, 200);
  			setTimeout(function() {
  				hold = false;
  			}, 1000);
  		}
		if (animation_hold === false) {
			animation_hold = true;
		  	restartAnimations();
			setTimeout(function() {
				animation_hold = false;
			}, 2000);
		}
  		//console.log(scdir + ':' + sleimgngth + ':' + plength + ':' + (plength - plength / pnls));
  	}
  	/*[swipe detection on touchscreen devices]*/
  	function _swipe(obj) {
  		var swdir,
  			sX,
  			sY,
  			dX,
  			dY,
  			threshold = 100,
  			/*[min distance traveled to be considered swipe]*/
  			slack = 50,
  			/*[max distance allowed at the same time in perpendicular direction]*/
  			alT = 500,
  			/*[max time allowed to travel that distance]*/
  			elT, /*[elapsed time]*/
  			stT; /*[start time]*/
  		obj.addEventListener('touchstart', function(e) {
  			var tchs = e.changedTouches[0];
  			swdir = 'none';
  			sX = tchs.pageX;
  			sY = tchs.pageY;
  			stT = new Date().getTime();
  			//e.preventDefault();
  		}, false);

  		obj.addEventListener('touchmove', function(e) {
  			//e.preventDefault(); /*[prevent scrolling when inside DIV]*/
  		}, false);

  		obj.addEventListener('touchend', function(e) {
  			var tchs = e.changedTouches[0];
  			dX = tchs.pageX - sX;
  			dY = tchs.pageY - sY;
  			elT = new Date().getTime() - stT;
  			if (elT <= alT) {
  				if (Math.abs(dX) >= threshold && Math.abs(dY) <= slack) {
  					swdir = (dX < 0) ? 'left' : 'right';
  				} else if (Math.abs(dY) >= threshold && Math.abs(dX) <= slack) {
  					swdir = (dY < 0) ? 'up' : 'down';
  				}
  				if (obj.id === 'well') {
  					if (swdir === 'up') {
  						scdir = swdir;
  						_scrollY(obj);
  					} else if (swdir === 'down' && obj.style.transform !== 'translateY(0)') {
  						scdir = swdir;
  						_scrollY(obj);

  					}
  					e.stopPropagation();
  				}
  			}
  		}, false);
  	}
  	/*[assignments]*/
  	var well_jq = $('#well');
    if (well_jq.length !== 0) {
      let well = well_jq[0];
    	well.style.transform = 'translateY(0)';
    	well.addEventListener('wheel', function(e) {
    		if (e.deltaY < 0) {
    			scdir = 'down';
    		}
    		if (e.deltaY > 0) {
    			scdir = 'up';
    		}
    		e.stopPropagation();
    	});
    	well.addEventListener('wheel', _scrollY);
    	_swipe(well);
    	
		$('.ball-hover-placeholder').on('click', function() {
			scdir = 'up';
			_scrollY(well);
		});
    }

  })();
});

function play_current_panel_vimeo(slength){
  //Safe to call anywhere
  let responsive_index = 0;
  let current_panel_index = (slength/(-100));
	if ($(window).width() >= 768) {
		responsive_index = 1;
	}
  if (current_panel_index === -0) { current_panel_index = 0 };
  let current_panel = $('.panel')[current_panel_index];
  let iframes = $(current_panel).find('.vimeo-init');

  if (iframes.length !== 0) {
	let iframe = iframes[responsive_index];
	let player = new Player(iframe);
	play(player);
  }
}
let play_animation = (bslength, slength) => {
    let b_panel_index = (bslength/(-100));
    let current_panel_index = (slength/(-100));
    if (current_panel_index === -0) { current_panel_index = 0 };
    let current_panel = $('.panel')[current_panel_index];
    let b_panel = $('.panel')[b_panel_index];
    $(b_panel).css("opacity", "0.9");
    setTimeout(function() {
        $(current_panel).css("opacity", "1");
    }, 150);
}

function pause_all_players() {
  //Safe to call anywhere
  let iframe = $('.vimeo-init');
  if (iframe.length !== 0) {
    iframe.each(function(index) {
      let player = new Player(this);
      pause(player);
    });
  }
}

function play(player){
  player.getPaused().then(function(paused) {
    if (paused) {
      player.play().then(function() {
        //player.getVideoTitle().then(function(title) {
          //console.log('Playing the video:', title);
        //});
      }).catch(function(error) {
        verbose_errors(error)
      });
    }
  });
}

function pause(player){
  player.getPaused().then(function(paused) {
    if (!paused){
      player.pause().then(function() {
      }).catch(function(error) {
        verbose_errors(error)
      });
    }
  });
}

function verbose_errors(error){
  switch (error.name) {
    case 'PasswordError':
        console.log("password error");
        break;
    case 'PrivacyError':
        console.log("private video error");
        break;
    default:
        console.log("other error ocurred: " + error.name);
        break;
  }
}

function carouselScrolling() {
	var scdir, hold = false;
	let carousels = $('.full-page-carousel');
	let body = $('body');

	function showAnotherCarouselSlide(obj) {
		if (hold === false) {
			hold = true;
			let carousels = $('.full-page-carousel');
			if (carousels.length !== 0) {
				carousels.each(function(index) {
					let carousel = new Carousel(this,{});
					if (scdir === 'up') {
						carousel.next();
					} else {
						carousel.prev();
					}
				})
			}

			setTimeout(function() {
				hold = false;
			}, 1000);
		}
	}

    if (carousels.length !== 0) {
      let _body = body[0];
    	_body.addEventListener('wheel', function(e) {
    		if (e.deltaY < 0) {
    			scdir = 'down';
    		}
    		if (e.deltaY > 0) {
    			scdir = 'up';
    		}
    		e.stopPropagation();
    	});
    	_body.addEventListener('wheel', showAnotherCarouselSlide);    
    }
}