import $ from 'jquery';
import Carousel from "bootstrap/js/src/carousel"

$(document).on('turbolinks:load', () => {
    let el1 = $('#carouselExampleControls');
    if (el1.length !== 0) {
        var car1 = document.querySelector('#carouselExampleControls')
        var carousel = new Carousel(car1, {
            interval: 10000,
        })
    }
    
    let el2 = $('#carouselExampleSlidesOnly');
    if (el2.length !== 0) {
        var car2 = document.querySelector('#carouselExampleSlidesOnly')
        var carousel = new Carousel(car2, {
            interval: 10000,
        })
    }  
});