import $ from 'jquery';

$(document).on('turbolinks:load', () => {
  let flash = true;

  hideOnHover();
  hideOnClickCross();
  hideOnClickCross2();

  openNavbarDropdown();
  if (flash) {
    flashSeeMoreText();
  }

  projectLinksMobileFix();
  changeCrossColour();
  gradualOpacityOnHover();
  //showScrollBallonHover();
  addCarouselProperties();
  openCategoriesMenu();
});

function projectLinksMobileFix() {
  // This allows users to tap on projects without going into the link straightforward.
  $('.top-level-link').on('click', (e) => {
    let el = $(e.currentTarget).find('.project-cover');
    if(el.css('display') == "block") {
      e.preventDefault();
    }
  });
}

function openNavbarDropdown (){
  $('.navbar-logo-wrapper').on('click', () => {
    let menu = $('.links-mobile-wrapper');
    menu.slideToggle();
  });
}

function flashSeeMoreText(){
  (function blink() {
    $('.scroll-to-see-more').fadeOut(1500).fadeIn(1500, blink);
  })();
}

function hideOnHover(){
  $(".hover-placeholder").on('mouseover', function(){
    $(this).parent().addClass('with-filter');
  });

  $(".hover-placeholder").on('mouseleave', function(){
    $(this).parent().removeClass('with-filter');
  });
}
function hideOnClickCross() {
  $('.trigger, .close-slider').on('click', function() {
    $('.slider').toggleClass('close');
  });
}
function hideOnClickCross2() {
  $('.desktop-slider-open, .close-slider-desktop, .project-description-desktop').on('click', function() {
    $('.slider-desktop').toggleClass('close');
    $('.slider-wrapper').toggleClass('behind');
  });
}

function changeCrossColour(){
  $('.read-full-post').on('mouseover', function(){
    $(this).find('g').css('fill', '#E2E2E4');
  });
  $(".read-full-post").on('mouseleave', function(){
    $(this).find('g').css('fill', '#1D1D1B');
  });
  $('.see-all-projects').on('mouseover', function(){
    $(this).find('g').css('fill', '#1D1D1B');
  });
  $(".see-all-projects").on('mouseleave', function(){
    $(this).find('g').css('fill', '#E2E2E4');
  });
}

function showScrollBallonHover(){
  $(".ball-hover-placeholder, .cover-for-ball-icon").on('mouseover', function(){
    $(this).parent().css('opacity', '100%');
  });

  $(".ball-hover-placeholder, .cover-for-ball-icon").on('mouseleave', function(){
    $(this).parent().css('opacity', '50%');
  });
}

function openCategoriesMenu() {
  $('.open-close-categories').on('click', function() {
    $('#responsive-categories-wrapper').toggleClass('d-none');
  });
}

function addCarouselProperties(){
  $(".cover-for-ball-icon").css('position', 'absolute');
  $(".cover-for-ball-icon").css('height', '150px');
  $(".cover-for-ball-icon").css('width', '150px');
  $(".cover-for-ball-icon").css('top', '0');
  $(".cover-for-ball-icon").css('left', '0');
  $(".cover-for-ball-icon").css('transform', 'translate(-54px, -54px)');
}

function calcDistance(element, point) {
  const c1 = element.left - point.left;
  const c2 = element.top - point.top;
  return Math.sqrt(c1 * c1 + c2 * c2);
}

function getScrollBallPos() {
  let $win = $(window);
  return { left: $win.width()*0.5, top: $win.height()*0.95 }
}

function gradualOpacityOnHover(){
  let $win = $(window), w = 0,h = 0, opacity = 1,
    getWidth = function() {
      w = $win.width();
      h = $win.height();
    };
  const scrollBall = $('.scroll-ball');
  if (scrollBall.length !== 0) {
    scrollBall.each(function( index ) {
      let current = $( this );
      let maxDistance = calcDistance(getScrollBallPos(), {left: 0, top: 0});
      $win.on('mousemove', function(e) {
        getWidth();
        opacity = 0.5 + 0.5 *(maxDistance - calcDistance(getScrollBallPos(), {left: e.clientX, top: e.clientY}))/maxDistance;
        current.find('.ball-wrapper').css('opacity', opacity);
      });
    });
  }
};
